// Set file variable
$filename: 'scss/custom/_nav.scss';

/*==============================================================================
  @Navigation - #{$filename}
==============================================================================*/

.nav {
  display: none;
  padding: 0.25em 0;
  background: $bg-header;
}
@include media-min('medium') {
  .nav {
    display: block;
    padding-bottom: 1em;
  }
}

.nav-menu {
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: space-between;

  > li {
    margin: 0 1px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }
  > li > a {
    display: block;
    padding: 0.75em 0;
    color: $white;
    text-align: center;
    border: none;
    border-radius: $border-radius;
  }
  > li > a:hover,
  > li > a:focus,
  > li:hover > a {
    color: $accent;
    text-decoration: none;
  }
  > li > a:focus {
    outline: none;
  }

  > li.current_page_item > a,
  > li.current_page_parent > a,
  > li.current_page_ancestor > a {
    color: $accent;
  }
}

@include media-min('large') {
  .nav-menu > li > a {
    font-size: px-to-em(18px);
  }
}
