// Set file variable
$filename: 'scss/custom/_buttons.scss';

/*==============================================================================
  @Buttons - #{$filename}
==============================================================================*/

.button {
  @include make-button();
  @include add-button-color((
    'background-deg' : 2%,
  ));
  // There are cases where we want button text to wrap
  white-space: normal;

  &.block {
    display: block;
  }
}
.button.default {
  @include add-button-color((
    'background' : rgba($black, 0.05),
    'hover' : (
      'background' : rgba($black, 0.1)
    )
  ));
}
.button.primary {
  @include add-button-color((
    'background' : transparent,
    'border' : 2px solid $accent,
    'color' : $accent,

    'hover' : (
      'background' : transparent,
      'border-color' : $color,
      'color' : $accent,
    ),

    'active' : (
      'background' : transparent,
      'border-color' : $color,
      'color' : $accent,
    ),
  ));
}
.button.outline {
  @include add-button-color((
    'background' : transparent,
    'border' : 2px solid $accent,
    'color' : $accent,

    'hover' : (
      'background' : transparent,
      'border-color' : $color,
      'color' : $accent,
    ),

    'active' : (
      'background' : transparent,
      'border-color' : $color,
      'color' : $accent,
    ),
  ));
}
