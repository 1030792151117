// Set file variable
$filename: 'scss/custom/_featured-action.scss';

/*==============================================================================
  @Featured Action - #{$filename}
==============================================================================*/

/**
 * Animation
 */
@keyframes waddle {
  from {
    transform: scale3d(1, 1, 1);
  }
  20%, 60% {
    transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, 3deg);
  }
  40%, 80% {
    transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, -3deg);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

/**
 * Structure
 */
.featured-action {
  position: relative;
  z-index: 105;
  margin: 1em 0 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid rgba($black, 0.1);

  ul {
    list-style: none;
    margin: 0;
  }

  ul li {
    margin: 0;
  }
}

@include media-min('medium') {
  .featured-action {
    margin: 0.5em 0 1em;
    padding-bottom: 0;
    border-bottom: none;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    ul li {
      display: flex;
      align-items: stretch;
      flex: 1 1 0;
    }

    ul li a {
      flex: 1 1 auto;
    }
  }
}

/**
 * List Items
 */
.featured-action {
  li + li {
    margin-top: 1em;
  }
}

@include media-min('medium') {
  .featured-action {
    li {
      border-bottom: none;
    }

    li + li {
      margin-top: 0;
      margin-left: 1.5em;
    }
  }
}

/**
 * Links
 */
.featured-action a {
  display: block;
  padding: 1em 2em;
  background: $secondary;
  border-radius: $border-radius;
  border: none;
  box-shadow: 0 1px 3px rgba($black, 0.15);
  text-align: center;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  transition: $transition;

  &:hover {
    background: $secondary;
    text-decoration: none;
    animation-name: waddle;
  }

  h2,
  p {
    margin: 0;
  }

  h2 {
    font-size: 20px;
    line-height: 1.5em;
    font-weight: normal;
    color: $white;
  }

  p {
    font-size: 14px;
    line-height: 1.5em;
    color: rgba($white, 0.5);
  }

  .icon {
    margin: 0;
    font-size: 40px;
    color: $white;
  }

  .content {
    min-width: 200px;
  }
}

@include media-min('medium') {
  .featured-action a {
    padding: 2em;

    .content {
      min-width: 0;
    }
  }
}

/**
 * Layout
 */
@mixin featured-action($layout: 'stack') {
  @if ($layout == 'stack') {
    .featured-action a {
      display: block;
      text-align: center;

      .icon {
        margin-right: 0;
      }
    }
  } @else if ($layout == 'flat') {
    .featured-action a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;

      .icon {
        margin-right: 20px;
      }
    }
  } @else {
    @warn 'The layout style `#{$layout}` does not exist in the `featured-action` mixin.';
  }
}

@include featured-action('stack');

@include media-min(400px) {
  @include featured-action('flat');
}
@include media-min('medium') {
  @include featured-action('stack');
}
