// Set file variable
$filename: 'scss/custom/_custom.scss';

/*==============================================================================
  @Structure - #{$filename}
==============================================================================*/

/**
 * Listing Promotions
 */
.listing-promotions a:hover {
  background-color: $shade;

  .experation-date .chip {
    background-color: $white;
  }
}

/**
 * Review Rating
 */
.review-rating {
  background-color: $shade;
}
